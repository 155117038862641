
// @ts-nocheck


export const localeCodes =  [
  "en",
  "nl"
]

export const localeLoaders = {
  en: [
    {
      key: "locale_en_46ts_ed48fe66",
      load: () => import("#nuxt-i18n/ed48fe66" /* webpackChunkName: "locale_en_46ts_ed48fe66" */),
      cache: true
    }
  ],
  nl: [
    {
      key: "locale_nl_46ts_d4d1a6d7",
      load: () => import("#nuxt-i18n/d4d1a6d7" /* webpackChunkName: "locale_nl_46ts_d4d1a6d7" */),
      cache: true
    }
  ]
}

export const vueI18nConfigs = [
  () => import("#nuxt-i18n/887749ea" /* webpackChunkName: "config_vue_45i18n_46ts_887749ea" */)
]

export const nuxtI18nOptions = {
  restructureDir: "i18n",
  experimental: {
    localeDetector: "",
    switchLocalePathLinkSSR: false,
    autoImportTranslationFunctions: false,
    typedPages: true,
    typedOptionsAndMessages: false,
    generatedLocaleFilePathFormat: "absolute",
    alternateLinkCanonicalQueries: false,
    hmr: true
  },
  bundle: {
    compositionOnly: true,
    runtimeOnly: false,
    fullInstall: true,
    dropMessageCompiler: false,
    optimizeTranslationDirective: true
  },
  compilation: {
    jit: true,
    strictMessage: true,
    escapeHtml: false
  },
  customBlocks: {
    defaultSFCLang: "json",
    globalSFCScope: false
  },
  locales: [
    {
      code: "en",
      files: [
        "/vercel/path0/i18n/language/en.ts"
      ]
    },
    {
      code: "nl",
      files: [
        "/vercel/path0/i18n/language/nl.ts"
      ]
    }
  ],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  trailingSlash: false,
  defaultLocaleRouteNameSuffix: "default",
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "language/",
  rootRedirect: undefined,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "",
  customRoutes: "config",
  pages: {
    contact: {
      en: "/contact",
      nl: "/contact"
    },
    gallery: {
      en: "/gallery",
      nl: "/galerij"
    },
    index: {
      en: "/",
      nl: "/"
    },
    sitemap: {
      en: "/sitemap",
      nl: "/sitemap"
    },
    "vacancies/index": {
      en: "/vacancies",
      nl: "/vacatures"
    },
    "vacancies/[item]": {
      en: "/vacancies/[item]",
      nl: "/vacatures/[item]"
    },
    "highlights/index": {
      en: "/highlights",
      nl: "/bezienswaardigheden"
    },
    "highlights/[item]": {
      en: "/highlights/:item",
      nl: "/bezienswaardigheden/[item]"
    },
    "events/index": {
      en: "/events",
      nl: "/events"
    },
    "events/[item]": {
      en: "/events/[item]",
      nl: "/events/[item]"
    },
    privacy: {
      en: "/privacy-policy",
      nl: "/privacy-policy"
    }
  },
  skipSettingLocaleOnNavigate: false,
  types: "composition",
  debug: false,
  parallelPlugin: false,
  multiDomainLocales: false,
  i18nModules: []
}

export const normalizedLocales = [
  {
    code: "en",
    files: [
      {
        path: "/vercel/path0/i18n/language/en.ts",
        cache: undefined
      }
    ]
  },
  {
    code: "nl",
    files: [
      {
        path: "/vercel/path0/i18n/language/nl.ts",
        cache: undefined
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false
export const hasPages = true

export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
/** client **/

/** client-end **/